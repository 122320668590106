import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.object.freeze";
// import Loading from 'quasar/src/plugins/Loading.js';
import Vue from 'vue';
import Vuex from 'vuex'; // import 'meteor.bundle' // Get meteor bundler in /node_modules

import { Meteor } from 'meteor/meteor';
import { Tracker } from 'meteor/tracker';
import { Adverts, Measures, Notices, Land, UserData } from 'api/collections';
import themes from "./theme-module";
import content from "./store-content"; // import createPersistedState from 'vuex-persistedstate'
// export const UserData = new Meteor.Collection('userData')

Meteor.subscribe('thisNameDoesNotMatter');
import VuexORM from '@vuex-orm/core';
import Uzer from 'classes/Uzer'; // import { Loading /*, debounce*/ } from 'quasar'

import Loading from 'quasar/src/plugins/Loading.js';;
Vue.use(Vuex);
var database = new VuexORM.Database();
database.register(Uzer);
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

var storeInstance;
/*
const deepFreeze = obj => {
    Object.keys(obj).forEach(prop => {
        if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) deepFreeze(obj[prop])
    })
    return Object.freeze(obj)
}
*/

export default function ()
/* { ssrContext } */
{
  var Store = new Vuex.Store({
    // plugins: [VuexORM.install(database), createPersistedState()],
    state: {
      user: undefined,
      userid: undefined,
      authenticated: false,
      measures: [],
      land: [],
      userData: [],
      measuresMine: [],
      score: -1,
      count: 0,
      ready: false,
      drawnFeature: undefined,
      connected: false,
      showToolbar: true,
      notice: undefined,
      adverts: undefined
    },
    modules: {
      themes: themes,
      content: content
    },
    mutations: {
      // clearAuthData (state) {
      //   // state.idToken = userData.token
      //   state.userId = 'klaas'
      // },
      UPDATE_USER_STATE: function UPDATE_USER_STATE(state, user) {
        if (user) {
          state.authenticated = true;
          state.user = user;
          state.userid = Meteor.userId();
        } else {
          state.user = user;
          state.userid = Meteor.userId();
          state.authenticated = false;
        }
      },
      UPDATE_MEASURES_STATE: function UPDATE_MEASURES_STATE(state, measures) {
        // console.log('UPDATE_MEASURES_STATE')
        // console.log(`count: ${state.count} | measures size:${measures.length} | Data: ${new Date()}`)
        // state.count = state.count + 1

        /* if (state.count === 1) {
            console.log('------1----------')
            // state.measures = Object.freeze(measures)
        } */
        if (state.ready) {
          // console.log('ready !!!....FREEZING MEASURES')
          state.measures = Object.freeze(measures);
        } else {} // console.log('NOT ready....')
        // state.measures = measures

        /* debounce(function () {
         }, 0 /!* ms to wait *!/, true) */

      },
      UPDATE_MY_MEASURES_STATE: function UPDATE_MY_MEASURES_STATE(state, measures) {
        state.measuresMine = measures.reverse();
        state.score = measures.length;
      },
      UPDATE_READY_STATE: function UPDATE_READY_STATE(state, value) {
        state.ready = value;
      },
      UPDATE_DRAWNFEATURE_STATE: function UPDATE_DRAWNFEATURE_STATE(state, value) {
        // console.log(value.payload)
        state.drawnFeature = value.payload;
      },
      UPDATE_CONNECTED_STATE: function UPDATE_CONNECTED_STATE(state, value) {
        state.connected = value;
      },
      UPDATE_SHOWTOOLBAR_STATE: function UPDATE_SHOWTOOLBAR_STATE(state, value) {
        state.showToolbar = value;
      },
      UPDATE_NOTICES_STATE: function UPDATE_NOTICES_STATE(state, value) {
        state.notice = value;
      },
      UPDATE_LAND_STATE: function UPDATE_LAND_STATE(state, value) {
        state.land = value;
      },
      UPDATE_USERDATA_STATE: function UPDATE_USERDATA_STATE(state, value) {
        state.userData = value;
      },
      UPDATE_ADVERTS_STATE: function UPDATE_ADVERTS_STATE(state, value) {
        state.adverts = value;
      }
    },
    // modules: {
    //     measures
    // },
    strict: process.env.DEV,
    actions: {
      storeDrawnFeature: function storeDrawnFeature(_ref, payload) {
        var commit = _ref.commit;
        // console.log(' action payloaddd')
        // console.log(payloadd)
        commit('UPDATE_DRAWNFEATURE_STATE', payload);
      },
      setShowToolbarState: function setShowToolbarState(_ref2, payload) {
        var commit = _ref2.commit;
        commit('UPDATE_SHOWTOOLBAR_STATE', payload);
      },
      initLoginStateTracker: function initLoginStateTracker(_ref3) {
        var commit = _ref3.commit;
        Tracker.autorun(function (c) {
          var user = Meteor.user();
          commit('UPDATE_USER_STATE', user);
        });
      },
      initConnectedStateTracker: function initConnectedStateTracker(_ref4) {
        var commit = _ref4.commit;
        Tracker.autorun(function (c) {
          var connected = Meteor.status().connected;
          commit('UPDATE_CONNECTED_STATE', connected);
        });
      },
      initAdvertsTracker: function initAdvertsTracker(_ref5) {
        var commit = _ref5.commit;
        Meteor.subscribe('adverts', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = Adverts.find({}, {
            sort: {
              'dateCreated': -1
            }
          }).fetch(); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })

          commit('UPDATE_ADVERTS_STATE', item);
        });
      },
      initNoticesTracker: function initNoticesTracker(_ref6) {
        var commit = _ref6.commit;
        Meteor.subscribe('notices', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = Notices.findOne({}, {
            sort: {
              'dateCreated': -1
            }
          }); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })

          commit('UPDATE_NOTICES_STATE', item);
        });
      },
      initLandTracker: function initLandTracker(_ref7) {
        var commit = _ref7.commit;
        Meteor.subscribe('land', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = Land.find({}, {
            sort: {
              'createdAt': -1
            }
          }).fetch(); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })
          // console.log('item', item)

          commit('UPDATE_LAND_STATE', item);
        });
      },
      initUserDataTracker: function initUserDataTracker(_ref8) {
        var commit = _ref8.commit;
        Meteor.subscribe('userData', {
          onReady: function onReady() {// console.log('onReady And the Items actually Arrive', arguments)
            // commit('UPDATE_READY_STATE', true)
          }
        });
        Tracker.autorun(function (c) {
          var item = UserData.find({}, {
            sort: {
              'createdAt': -1
            }
          }).fetch(); // .map((item) => {
          //     let userData = UserData.findOne({ _id: item.properties.createdBy })
          //     item.properties.meazure = getMeazure(item)
          //     item.properties.userData = userData
          //     return item
          // })
          // console.log('item', item)

          commit('UPDATE_USERDATA_STATE', item);
        });
      },
      initMeasuresTracker: function initMeasuresTracker(_ref9) {
        var commit = _ref9.commit;
        Meteor.subscribe('measures', {
          onReady: function onReady() {
            // console.log('onReady And the Items actually Arrive', arguments)
            Loading.hide();
            commit('UPDATE_READY_STATE', true);
          }
        });
        Tracker.autorun(function (c) {
          // let items = Measures.find({}, { sort: { dateCreated: -1 }, limit: 5 })
          var items = Measures.find({}, {
            sort: {
              'dateCreated': -1
            }
          }).map(function (item) {
            var userData = UserData.findOne({
              _id: item.createdBy
            });
            item.userData = userData; // item.properties = null

            return item;
          }); // console.log(items)

          commit('UPDATE_MEASURES_STATE', items);
        });
        Tracker.autorun(function (c) {
          var currentUserId = Meteor.userId(); // let items = Measures.find({ 'properties.createdBy': currentUserId }, { sort: { dateCreated: -1 }, limit: 1 })

          var items = Measures.find({
            'createdBy': currentUserId
          }, {
            sort: {
              'dateCreated': -1
            }
          }).map(function (item) {
            var userData = UserData.findOne({
              _id: item.createdBy
            });
            item.userData = userData; // return {
            //     ...item,
            //     foo2: 'bar2'
            // }

            return item;
          });
          commit('UPDATE_MY_MEASURES_STATE', items);
        });
      },
      logout: function logout(_ref10) {
        var commit = _ref10.commit;
        commit('clearAuthData'); // localStorage.removeItem('expirationDate')
        // localStorage.removeItem('token')
        // localStorage.removeItem('userId')
        // router.replace('/signin')
      } // signup ({ commit }, payload) {
      //     commit('changeCCC', payload)
      //     // localStorage.removeItem('expirationDate')
      //     // localStorage.removeItem('token')
      //     // localStorage.removeItem('userId')
      //     // router.replace('/signin')
      // }

    },
    getters: {
      // thread: state => id =>
      // {
      //     let thread = null
      //     state.land.forEach(land => {
      //         return land.threads.find(thread => thread.id === id)
      //     })
      //     return thread
      // },
      isSubscribed: function isSubscribed(state) {
        return function (id) {
          if (state.user) {
            return state.user.profile.subs.indexOf(id) !== -1;
          } else {
            return false;
          }
        };
      },
      landByMeetingId: function landByMeetingId(state) {
        return function (id) {
          return state.land.find(function (land) {
            return land.meetings.find(function (meeting) {
              return meeting.id === id;
            });
          });
        };
      },

      /* landByTag: state => tag => state.land.find(land => {
              return land.tags && land.tags.indexOf(tag) !== -1
          }
      ), */
      landByTag: function landByTag(state) {
        return function (id) {
          return state.land.find(function (land) {
            if (land.tags) {
              return land.tags.find(function (tag) {
                return tag.id === id;
              });
            } else return null;
          });
        };
      },
      landByThreadId: function landByThreadId(state) {
        return function (id) {
          return state.land.find(function (land) {
            return land.threads.find(function (thread) {
              return thread.id === id;
            });
          });
        };
      },
      landByPollId: function landByPollId(state) {
        return function (id) {
          return state.land.find(function (land) {
            // console.log(land)
            return land.polls.find(function (poll) {
              return poll.id === id;
            });
          });
        };
      },
      landByPolisId: function landByPolisId(state) {
        return function (id) {
          return state.land.find(function (land) {
            // console.log(land)
            return land.polis.find(function (polis) {
              return polis.id === id;
            });
          });
        };
      },
      landByPledgeId: function landByPledgeId(state) {
        return function (id) {
          return state.land.find(function (land) {
            // console.log(land)
            return land.pledges.find(function (pledge) {
              return pledge.id === id;
            });
          });
        };
      },
      measure: function measure(state) {
        return function (id) {
          return state.measures.find(function (measure) {
            return measure.id === id;
          });
        };
      },
      userData: function userData(state) {
        return function (id) {
          return state.userData.find(function (item) {
            return item._id === id;
          });
        };
      },
      userDataAll: function userDataAll(state, getters) {
        return state.userData;
      },
      land: function land(state) {
        return function (id) {
          return state.land.find(function (land) {
            return land.id === id;
          });
        };
      },
      measures: function measures(state, getters) {
        // return state.measures.filter(measure => !measure.like)
        return state.measures;
      },
      lands: function lands(state, getters) {
        // return state.measures.filter(measure => !measure.like)
        return state.land;
      },
      measuresNotLike: function measuresNotLike(state, getters) {
        return state.measures.filter(function (measure) {
          return !measure.like === true;
        });
      },
      measuresMine: function measuresMine(state, getters) {
        return state.measuresMine;
      },
      communitySubscribed: function communitySubscribed(state, getters) {
        if (state.user) {
          if (state.user.profile.owner) {
            return state.land.filter(function (land) {
              return land.createdBy === state.userid;
            });
          } else {
            var subIds = state.user.profile.subs;
            return state.land.filter(function (land) {
              return subIds.indexOf(land.id) !== -1;
            });
          }
        } else {
          return [];
        }
      },
      landsOwner: function landsOwner(state, getters) {
        if (state.user) {
          if (state.user.profile.superadmin) {
            return state.land;
          } else {
            return state.land.filter(function (land) {
              return land.createdBy === state.userid;
            });
          } // let subIds = state.user.profile.subs

        } else {
          return [];
        }
      },
      isModerator: function isModerator(state, getters) {
        return function (id) {
          if (id && state.authenticated) {
            var land = getters.land(id);
            return land.mods.includes(state.user._id);
          } else {
            return false;
          } // let land = getters.land(id)
          // let bool =
          //
          // return state.authenticated && bool

        };
      },
      isOwner: function isOwner(state, getters) {
        return function (id) {
          if (id && state.authenticated) {
            if (state.user.superadmin) {
              return true;
            } else {
              var land = getters.land(id);
              return land.createdBy === state.user._id;
            }
          } else {
            return false;
          } // let land = getters.land(id)
          // let bool =
          //
          // return state.authenticated && bool

        };
      },
      isAuthenticated: function isAuthenticated(state) {
        return state.authenticated;
      },
      userid: function userid(state) {
        return state.userid;
      },
      user: function user(state) {
        return state.user;
      },
      getCenter: function getCenter(state) {
        // return state.authenticated ? state.user.profile.centerCoordinates : [6.589560, 53.214578]
        return state.authenticated ? state.user.profile.centerCoordinates : [6.589560, 53.214578];
      },
      bannerAd: function bannerAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'BANNER';
          });
          return result;
        }
      },
      timelineAd: function timelineAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'TIMELINE';
          });
          return result;
        }
      },
      tipsVoedselAd: function tipsVoedselAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'TIPSVOEDSEL';
          });
          return result;
        }
      },
      tipsNestelAd: function tipsNestelAd(state) {
        if (state.adverts) {
          var result = state.adverts.find(function (ad) {
            return ad.position === 'TIPSNESTEL';
          });
          return result;
        }
      } // getScore (state) {
      //     let score = -1
      //     if (state.authenticated) {
      //         score = state.measures.filter(measure => measure.createdBy === state.user.id)
      //     }
      //     console.log('START')
      //     console.log(state.user)
      //     console.log(state.user.id)
      //     let
      //     console.log(score)
      //     console.log('END')
      //     return score
      // }

    }
  });
  storeInstance = Store;
  return Store;
}
export { storeInstance };