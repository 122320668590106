/*
 * This file is picked up by the build system only
 * when building for PRODUCTION
 */
import Notify from 'quasar/src/plugins/Notify.js';;
import { register } from 'register-service-worker';
register(process.env.SERVICE_WORKER_FILE, {
  ready: function ready() {
    console.log('App is being served from cache by a service worker.');
  },
  registered: function registered(registration) {
    // registration -> a ServiceWorkerRegistration instance
    console.log('Service worker has been registered.');
  },
  cached: function cached(registration) {
    // registration -> a ServiceWorkerRegistration instance
    console.log('Content has been cached for offline use.');
  },
  updatefound: function updatefound(registration) {
    // registration -> a ServiceWorkerRegistration instance
    console.log('New content is downloading.');
  },
  updated: function updated(registration) {
    // registration -> a ServiceWorkerRegistration instance
    // console.log('New content is available; please refresh. X')
    var notif = Notify.create({
      group: false,
      // required to be updatable
      timeout: 0,
      // we want to be in control when it gets dismissed
      spinner: true,
      message: 'Updating application...',
      caption: '0%'
    }); // we simulate some progress here...

    var percentage = 0;
    var interval = setInterval(function () {
      percentage = Math.min(100, percentage + Math.floor(Math.random() * 22)); // we update the dialog

      notif({
        caption: "".concat(percentage, "%")
      }); // if we are done...

      if (percentage === 100) {
        notif({
          icon: 'done',
          // we add an icon
          spinner: false,
          // we reset the spinner setting so the icon can be displayed
          message: 'Done! Reloading application...',
          timeout: 2500,
          onDismiss: function onDismiss() {
            // location.replace('https://friends.greenmapper.org/welcome/')
            location.reload(true);
          }
        });
        clearInterval(interval);
      }
    }, 500); // this.forceSWupdate();
    // Notify.create({
    //     // message: i18n.t('messages.update_available'),
    //     message: 'Xena',
    //     icon: 'announcement',
    //     onDismiss () {
    //         location.reload(true)
    //     }
    // })
  },
  offline: function offline() {
    console.log('No internet connection found. App is running in offline mode.');
  },
  error: function error(err) {
    console.error('Error during service worker registration:', err);
  }
}); // ServiceWorkerRegistration: https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration